export const globalConfig = {
  'sessionTimeout': 3600,
  'api': {
    'oauth': {
      'token': '/api/oauth/token',
      'logout': '/api/oauth/logout',
      'ssoLogin': '/api/oauth/sso/login'
    },
    'docValues': '/api/doc/values',
    'chartstat': {
      'users': '/api/doc/stat/users',
      'datachart': '/api/doc/stat/datachart',
      'surveys': '/api/doc/stat/surveys',

    },
    'authuser': {
      'projects': '/api/dicts/user/projects',
      'access': '/api/dicts/user/acc',
      'messages': '/api/messages',
      'analyticsDashboard': '/api/dicts/user/analytics/dashboards'
    },
    'projects': {
      'oper': '/api/dicts/projects',
      'users': '/api/dicts/users',
      'others': '/api/dicts/project'
    },
    'account': {
      'users': '/api/account/users',
      'groups': '/api/account/groups',
      'organizations': '/api/account/organizations',
      'registration': '/api/account/user/registration',
      'common': '/api/account',
      'auth': '/api/account/user',
      'authInfo': '/api/account/user/info',
      'authCheckProjectAdmin': '/api/account/user/check-project-admin'
    },
    'log': {
      'connects': '/api/logs/connects',
      'public': '/api/logs/public',
      'private': '/api/logs/private',
      'templates': '/api/logs/templates'
    },
    'publicImport': '/api/doc/public/import',
    'import': '/api/doc/import',
    'export': '/api/doc/export',
    'download': '/api/logs/download',
    'builder': '/api/doc/builder',
    'bidata': '/api/doc/bidata',
    'dataupload': '/api/doc/dataupload',
    'rule': '/api/doc/rule',
    'recordOwners': '/api/doc/record-owners',
    'dicts': {
      'public': {
        'states': '/api/dicts/public/states',
        'tables': '/api/dicts/public/tables'
      },
      'periods': '/api/dicts/periods',
      'sprdocs': '/api/dicts/sprdocs',
      'charts': '/api/dicts/charts',
      'subdocs': '/api/dicts/docutl/subdocs/',
      'docutl': '/api/dicts/docutl',
      'states': '/api/dicts/states',
      'docs': '/api/dicts/docs',
      'pubDocs': '/api/dicts/public/docs',
      'surveys': '/api/dicts/public/docs/surveys',
      'docterms': '/api/dicts/docterm',
      'templates': '/api/dicts/templates',
      'builder': '/api/dicts/builder',
      'tables': '/api/dicts/tables',
      'countries': '/api/dicts/countries',
      'tags': '/api/dicts/tags',
      'buttons': '/api/dicts/buttons'
    },
    'docutl': {
      'public': {
        'createCheck': '/api/doc/public/utl/create/check',
        'createEmpty': '/api/doc/public/utl/create/empty',
        'save': '/api/doc/public/utl/save',
        'send': '/api/doc/public/utl/send',
        'isExists': '/api/doc/public/utl/check-exist',
        'check': '/api/doc/public/utl/check',
        'tmpl': '/api/doc/public/tmpl',
        'dataxml': '/api/doc/public/utl/xml',
        'datajson': '/api/doc/public/utl/json',
        'status': '/api/doc/public/utl/status',
        'download': '/api/doc/public/utl/download',
        'getlist': '/api/doc/public/utl/docs',
        'access_token': '/api/doc/public/utl/public-link',
        'checkToken': '/api/doc/public/utl/public-link/validate'
      },
      'values': '/api/doc/utl/values',
      'project': '/api/doc/project',
      'link': '/api/doc/utl',
      'tmpl': '/api/doc/tmpl'
    },
    'fs': '/api/fs'
  }
};
