import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StartComponent} from './components/start/start.component';
import {AuthGuard} from './_services/auth.guard';
import {HomeComponent} from './components/home/home.component';
import {SsoLoginComponent} from './components/registration/sso-login/sso-login.component';
import {PageReportEditorComponent} from './components/doceditor/page-report-editor/page-report-editor.component';
import {PublicReportEditorComponent} from './components/doceditor/public-report-editor/public-report-editor.component';
import {ThanksOfSubmittedComponent} from './components/doceditor/thanks-of-submitted/thanks-of-submitted.component';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'sso-login/:id', component: SsoLoginComponent},
  {path: 'home', component: StartComponent, canActivate: [AuthGuard]},
  {path: 'reports/view/:id', component: PageReportEditorComponent, canActivate: [AuthGuard]},
  {path: 'public/report/view', component: PublicReportEditorComponent},
  {path: 'public/report/submitted', component: ThanksOfSubmittedComponent},
  {
    path: 'public',
    loadChildren: () =>
      import('src/app/components/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('src/app/components/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('src/app/components/projects/projects.module').then((m) => m.ProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stat',
    loadChildren: () =>
      import('src/app/components/statistic/statistic.module').then((m) => m.StatisticModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics-dashboard/:dashboardId',
    loadComponent: () =>
      import('src/app/components/analytics-dashboard/analytics-dashboard.component').then((m) => m.AnalyticsDashboardComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('src/app/components/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [AuthGuard]
  },
  {path: '**', component: StartComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
