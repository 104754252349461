export class EnvService {
  public clientId = '';
  public googleMapsApiKey = '';
  public spEntityId: '';
  public idpEntityId: '';
  public supersetDomain: '';

  constructor() {
  }
}
